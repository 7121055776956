import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import YouTube from 'react-youtube';
import classNames from 'classnames';

import { RecommendItem } from 'data/Recommend';
import { isClientOnMobile } from 'utils/mobile';

const TIMEOUT = 10000;

let currentTimeout: number | undefined = undefined;
function setTimeout(fn: () => void) {
  if (typeof window === 'undefined') {
    return;
  }

  window.clearTimeout(currentTimeout);
  currentTimeout = window.setTimeout(() => fn(), TIMEOUT);
}

async function preloadImage(
  uid: string,
  imageUrl?: string | null,
): Promise<void> {
  const image = new Image();
  image.src =
    imageUrl ?? `${process.env.NEXT_PUBLIC_API_URL}/media/page/${uid}`;
  await image.decode();
}

interface RecommendProps {
  recommends: RecommendItem[];
}

function Recommend({ recommends }: RecommendProps) {
  const { t } = useTranslation('common');
  const [pause, setPause] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [current, setCurrent] = useState(0);

  const slideRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<YouTube>(null);

  const slides: RecommendItem[] = useMemo(() => {
    return recommends.filter(r => r.page_locale);
  }, [recommends]);

  const preloadSlides = useCallback(() => {
    slides.forEach(async s => {
      await preloadImage(s.uid, s.image_url);
    });
  }, [slides]);

  const change = useCallback(
    async (index: number) => {
      setAnimate(false);
      setPause(true);

      const slide = slides[index];
      const isVideo = slide.video && !isClientOnMobile();

      await preloadImage(slide.uid, slide.image_url);

      setCurrent(index);
      setAnimate(true);
      setPause(false);

      void slideRef.current?.offsetWidth;

      if (isVideo) {
        return;
      }

      setTimeout(async () => {
        if (slides.length === 1) {
          return;
        }

        if (slides.length === index + 1) {
          await change(0);
          return;
        }

        await change(index + 1);
      });
    },
    [slides],
  );

  const next = useCallback(async () => {
    if (slides.length === 1) {
      return;
    }

    if (slides.length === current + 1) {
      await change(0);
      return;
    }

    await change(current + 1);
  }, [change, current, slides.length]);

  const back = useCallback(async () => {
    if (slides.length === 1) {
      return;
    }

    if (current - 1 < 0) {
      await change(slides.length - 1);
      return;
    }

    await change(current - 1);
  }, [change, current, slides]);

  useEffect(() => {
    preloadSlides();
    change(0);
  }, [change, preloadSlides, slides]);

  if (slides.length === 0) {
    return (
      <div>
        <div className="Recommend__title">{t('RECOMMEND_TITLE')}</div>
        <div className="Recommend">
          <div className="Recommend__slides" />
        </div>
        <div className="Recommend__control" />
      </div>
    );
  }

  const slide = slides[current];
  return (
    <div className="Recommend__wrapper">
      <div className="Recommend__title">{t('RECOMMEND_TITLE')}</div>
      <div className="Recommend">
        <Link href={slide.page_locale.path} legacyBehavior>
          <a className="Recommend__slides">
            {slide.video && !isClientOnMobile() ? (
              <div
                ref={slideRef}
                className={classNames('Recommend__slidesItem', {
                  'Recommend__slidesItem--visible': slides.length === 1,
                  'Recommend__slidesItem--animate':
                    slides.length > 1 && animate,
                  'Recommend__slidesItem--paused': slides.length > 1 && pause,
                  'Recommend__slidesItem--video':
                    slide.video && !isClientOnMobile(),
                })}
              >
                <YouTube
                  ref={playerRef}
                  videoId={slide.video.identifier}
                  opts={{
                    playerVars: {
                      autoplay: 1,
                      controls: 0,
                      showinfo: 0,
                      rel: 0,
                      loop: 1,
                      playlist: slide.video.identifier,
                      mute: 1,
                      start: slide.video.time,
                    },
                  }}
                  onStateChange={async e => {
                    if (e.data === -1) await next();
                  }}
                />
              </div>
            ) : (
              <div
                ref={slideRef}
                className={classNames('Recommend__slidesItem', {
                  'Recommend__slidesItem--visible': slides.length === 1,
                  'Recommend__slidesItem--animate':
                    slides.length > 1 && animate,
                  'Recommend__slidesItem--paused': slides.length > 1 && pause,
                  'Recommend__slidesItem--video':
                    slide.video && !isClientOnMobile(),
                })}
                style={{
                  backgroundImage: slide.image_url
                    ? `url(${slide.image_url})`
                    : `url(${process.env.NEXT_PUBLIC_API_URL}/media/page/${slide.uid})`,
                }}
              />
            )}
          </a>
        </Link>
      </div>
      <div className="Recommend__control">
        <Link href={slide.page_locale.path} passHref legacyBehavior>
          <a>
            <h2
              className={classNames({
                visible: slides.length === 1,
                animate: slides.length > 1 && animate,
                paused: slides.length > 1 && pause,
                video: slide.video && !isClientOnMobile(),
              })}
            >
              <span>{slide.title}</span>
            </h2>
          </a>
        </Link>
        {slides.length > 1 && (
          <div>
            <button
              type="button"
              onClick={back}
              className="Recommend__controlButton Recommend__controlButton--left"
            >
              {t('RECOMMEND_PREVIOUS')}
            </button>
            <button
              type="button"
              onClick={next}
              className="Recommend__controlButton Recommend__controlButton--right"
            >
              {t('RECOMMEND_NEXT')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Recommend;
