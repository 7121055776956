import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { LinkItem } from 'data/Link';

interface UsefulLinksProps {
  links: LinkItem[];
}

function UsefulLinks({ links }: UsefulLinksProps) {
  const { t } = useTranslation('common');
  return (
    <div className="UsefulLinks">
      <h3 className="Flag">{t('LINKS_TITLE')}</h3>
      <div className="UsefulLinks__content">
        {links.map(({ title, subtitle, is_external, link }, key) => {
          if (is_external) {
            return (
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                key={key}
                className="UsefulLinks__item"
              >
                <div className="UsefulLinks__title UsefulLinks__title--external">
                  {title}
                </div>
                <div className="UsefulLinks__description">{subtitle}</div>
              </a>
            );
          }

          return (
            <Link href={link} key={key} passHref legacyBehavior>
              <a className="UsefulLinks__item">
                <div className="UsefulLinks__title">{title}</div>
                <div className="UsefulLinks__description">{subtitle}</div>
              </a>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default UsefulLinks;
