import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { PickItem } from 'data/Pick';

interface PickedPagesProps {
  picks: PickItem[];
}

function PickedPages({ picks }: PickedPagesProps) {
  const { t } = useTranslation('common');

  return (
    <div className="PickedPages">
      <h3 className="Flag">{t('PICKED_TITLE')}</h3>
      <div className="PickedPages__content">
        {picks
          .filter(p => p.page_locale)
          .map(({ page_locale, uid, category, image_url }) => (
            <Link href={page_locale.path} key={uid} passHref legacyBehavior>
              <a
                className="PickedPages__item"
                style={{
                  backgroundImage: image_url
                    ? `url(${image_url})`
                    : `url(${process.env.NEXT_PUBLIC_API_URL}/media/page/${uid})`,
                }}
              >
                <div className="PickedPages__control">
                  <div className="PickedPages__category">{category}</div>
                  <h4 className="PickedPages__title">{page_locale.title}</h4>
                </div>
              </a>
            </Link>
          ))}
      </div>
    </div>
  );
}

export default PickedPages;
